.content {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  border: none;
  outline: none !important;
}
.container {
  display: flex;
  flex-direction: column;
}
.top {
  width: 500px;
  height: 10vh;
  background-color: #0b2447;
  display: flex;
  border-radius: 20px;
  z-index: 100;
  box-shadow: 0px 1px 5px;
  padding: 10px;
  flex-direction: row;
}
.bottom {
  width: 500px;
  height: 60vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: -35px;
  border-radius: 20px;
  padding-top: 40px;
  align-items: center;
}
.close {
  width: 25px;
  height: 25px;
  transition: 300ms;
  border-radius: 50%;
}
.close:hover {
  background-color: #277ffb;
  border-radius: 50%;
}
.loginTextDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 435px;
  justify-content: space-around;
}
.loginText {
  color: white;
  font-size: larger;
  font-weight: bolder;
}

.from {
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.input {
  height: 50px;
  width: 300px;
  outline: none;
  border: none;
  background: no-repeat scroll 7px 7px;
}
.btn {
  height: 50px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #277ffb;
  margin-top: 24px;
  font-weight: bolder;
  font-size: 13px;
  color: white;
}
.btn:disabled {
  background-color: gray;
  cursor: auto;
}
.inputMessage {
  height: 100px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  padding-left: 16px;
  margin-top: 10px;
  border: 1px solid gray;
  background: url(../../images/profile.png) no-repeat scroll 7px 7px;
  padding-left: 30px;
}
.inputComponent {
  border: 1px solid gray;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 300px;
  align-items: center;
}
.icons {
  width: 30px;
  aspect-ratio: 1/1;
  margin-top: -5px;
  margin-left: 10px;
}
.fromText {
  color: gray;
}
.btn {
  height: 50px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #277ffb;
  font-weight: bolder;
  font-size: 13px;
  color: white;
}
.loginAniamtion {
  width: 80px;
}
.backToLogin {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.bottom2 {
  width: 500px;
  height: 50vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: -35px;
  border-radius: 20px;
  padding-top: 40px;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .top {
    position: relative;
    width: 350px;
    height: 10vh;
    background-color: #0b2447;
    display: flex;
    border-radius: 20px;
    z-index: 100;
    box-shadow: 0px 1px 5px;
    padding: 10px;
    flex-direction: row;
  }

  .close {
    width: 25px;
    height: 25px;
    transition: 300ms;
    border-radius: 50%;
    position: absolute;
  }

  .container {
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
  }
  .bottom2 {
    background-color: white;
    margin-top: -35px;
    width: 350px;
    height: 350px;
  }
  .bottom {
    background-color: white;
    width: 350px;
    border-radius: 20px;
    height: 550px;
  }
}
