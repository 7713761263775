.background {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  position: relative;
}
.whiteTruck {
  width: 200px;
}
.loadingTruck {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav {
  width: 100%;
}
.preventLandScape {
  display: none;
}
.footerMob {
  display: none;
}
.maintext {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
}
.maintextLeft {
  width: 100%;
  display: flex;
  height: 55vh;
  justify-content: center;
  font-size: larger;
  font-weight: bolder;
  margin-top: -70px;
  align-items: flex-start;
}
.mainTextText {
  font-size: 120px;
  font-weight: bolder;
  color: white;
  text-align: center;
  justify-content: center;
  transition: 400ms ease-in-out;
  margin-top: 60px;
}
.maintextRight {
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
}
/* .orderBtn {
  width: 200px;
  height: 100px;
  border: none;
  background-color: white;
  border-radius: 20px;
  font-size: larger;
  font-weight: bolder;
  margin-top: -50px;
  color: #0b2447;
  cursor: pointer;
  transition: 400ms ease;

}



.orderBtn:hover {
  box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px,
    rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px,
    rgba(240, 46, 170, 0.05) 25px 25px;
} */

/* CSS */
.orderBtn {
  all: unset;
  width: 200px;
  height: 80px;
  font-size: 25px;
  background: transparent;
  border: none;
  position: relative;
  color: white;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: 300ms ease;
}
.orderBtn:hover {
  color: #0b2447;
}

.orderBtn::after,
.orderBtn::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.orderBtn::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #17498e;
  border-radius: 10px;
}

.orderBtn::after {
  transform: translate(10px, 10px);
  width: 0px;
  height: 0px;
  background-color: rgba(255, 255, 255);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  border-radius: 50px;
}

.orderBtn:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.orderBtn:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.orderBtn:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

.shipping {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  justify-content: space-around;

  color: white;

  font-size: large;
  font-weight: bolder;
}
.who {
  height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  background-color: #0b2447;
  justify-content: space-around;
  color: #7caaf4;
  font-size: large;
  font-weight: bolder;
}
.what {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #0b2447;
  height: 50vh;
  padding: 10px;
  justify-content: flex-start;

  align-items: center;
  overflow-x: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.what::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.leftArrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  left: 0;
  color: white;
  z-index: 100;
  height: 50vh;
  background: linear-gradient(90deg, #0b2447 0%, #02002400 100%);
  transition: 150ms;
  opacity: 1;
  cursor: pointer;
  /* pointer-events: none; */
}
.leftArrow img {
  transform: rotate(180deg);
}
.leftArrow.closed {
  opacity: 0;
}
.rightArrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  right: 0;
  color: white;
  z-index: 100;
  height: 50vh;
  background: linear-gradient(90deg, #02002400 0%, #0b2447 100%);
  transition: 150ms;
  opacity: 1;
  cursor: pointer;
  /* pointer-events: none; */
}
.rightArrow.closed {
  opacity: 0;
}
.partnerwraper {
  display: none;
}
.whatItem {
  min-width: 350px;
  flex-direction: column;
  display: flex;
  height: 45vh;
  border-radius: 20px;
  background-color: #cbcbd4;
  margin-right: 10px;
  transition: 300ms;
}
.whatItem:hover {
  margin-top: -20px;
}

.whatImgDiv {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 200px;
}
.whatImg {
  width: 300px;
}
.whatLinkDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  height: calc(45vh - 200px);
  padding-top: 60px;
}
.whatLinkText {
  font-size: large;
  font-weight: bold;
  color: #0b2447;
  text-decoration: none;
}

.item1 {
  width: 25%;
  flex-direction: column;
  display: flex;
}
.item1:hover {
  width: 25%;
  flex-direction: column;
  display: flex;
  margin-top: -10px;
  transition: 300ms;
}
.item2 {
  width: 25%;
  flex-direction: column;
  display: flex;
}
.item3 {
  width: 25%;
  flex-direction: column;
  display: flex;
}
.item4 {
  width: 25%;
  flex-direction: column;
  display: flex;
}
.itemsdesc {
  background-color: #3a4f7a;
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: column;
}
.itemsdesc2 {
  background-color: #485795;
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: column;
}
.howText {
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  background-color: #cbcbd4;
  color: #0b2447;
  height: 20vh;
  font-size: larger;
  font-weight: bolder;
}
.how {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: row;
  height: 25vh;
  background-color: #cbcbd4;
  align-items: center;
}
.howItem {
  display: flex;
  flex-direction: row;
  min-width: 40%;
  background-color: #0b2447;
  height: 20vh;
  transition: 300ms;
  border-radius: 20px;
}
.howItem:hover {
  width: 33.33%;
  background-color: #0b2447;
  text-align: center;
  height: 20vh;
  border: 1px solid black;
  margin-top: -20px;
}
.howItemImgDiv {
  display: flex;
  flex-direction: row;
  width: 30%;
  height: 20vh;
  align-items: center;
  justify-content: center;
}
.howItemImg {
  width: 100px;
  margin-top: -20px;
}
.howItemText {
  font-size: larger;
  font-weight: bold;
  color: #2196f3;
}
.howItemTextDiv {
  width: 70%;
  height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 60px;
  height: 60px;
}
.footerDivider {
  width: 100%;
  height: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0b2447;
}
.innerFooterDivider {
  width: 90%;
  height: 1vh;
  border-bottom: 1px solid #2196f3;
}
.footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 30vh;
  background-size: cover;
  align-items: center;
  text-align: center;
  background-color: #0b2447;
  justify-content: space-around;
}
.innerFooter {
  width: 90%;
  height: 30vh;
  display: flex;
  flex-direction: row;
}
.footerMain {
  width: 40%;

  display: flex;
  flex-direction: column;
  text-align: left;
  height: 30vh;
  color: white;
  font-size: large;
  font-weight: bolder;
  justify-content: space-between;
}
.rightFooter {
  display: flex;
  width: 60%;
  height: 30vh;
  flex-direction: row;
  justify-content: space-between;
}
.footerAnimationDiv {
  display: flex;
  width: 30%;
  height: 29vh;
  align-items: center;
  justify-content: center;
}
.footerLinks {
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 29vh;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  font-size: 20px;
}
.footerAnimation {
  width: 150px;
}
.footerIcons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}
.icons {
  width: 45px;
  height: 45px;
}
.icon8 {
  text-decoration: none;
  color: white;
}
.comment {
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #0b2447;
}
.commentTextDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20vh;
  justify-content: center;
  align-items: center;
}
.commentText {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 4vh;
  color: #7caaf4;
  font-size: larger;
  font-weight: bolder;
}
.commentCardSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* text-align: center; */
  height: 50vh;
  overflow-x: scroll;
  padding-right: 20px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.commentCardSection::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.commentCard {
  margin-left: 20px;
  width: 400px;
  height: 45vh;
  background-color: #cbcbd4;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}
.commentImg {
  margin-top: 30px;
  width: 130px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}
.userComment {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  color: white;
  font-size: large;
  text-align: justify;
  text-justify: inter-word;
}
.universeLogoDiv {
  display: none;
}
.burger {
  display: none;
}
.seeMore {
  display: none;
}
.ourPartnersTextDiv {
  display: none;
}

.ourPartnersCardDiv {
  display: none;
}

.ourServicesTextDiv {
  display: none;
}

.ourServicesCardDiv {
  display: none;
}

.clientsSayTextDiv {
  display: none;
}

.ourClientsSayCardDiv {
  display: none;
}

.top {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.topImg {
  width: 60px;
}
.mobText {
  display: none;
}
.backgroundMob {
  display: none;
}

@media only screen and (max-width: 600px) {
  .main {
    display: none;
  }
  .mainContext {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;
  }
  .mobText {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
    height: 500px;
    font-size: 55px;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
  }
  .partnerwraper {
    display: flex;
  }
  .mobOrderBtn {
    width: 150px;
    color: white;
    background-color: #0b2447;
    border: none;
    height: 50px;
    border-radius: 15px;
    font-size: 20px;
  }
  .backgroundMob {
    width: 100%;
    height: 100svh;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .menu {
    background-color: transparent;
    width: 70px;
    height: 30px;
  }
  .menuDiv {
    position: absolute;
    top: 8px;
    left: 8px;
    display: flex;
    flex-direction: row;
    height: 50px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
  .universeLogo {
    width: 200px;
    height: 42px;
    aspect-ratio: 1 / 1;
    background-color: transparent;
    display: flex;
  }
  .universeLogoDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    padding: 5px;
    border-radius: 20px 0px;
  }
  .burger {
    width: 30px;
    display: flex;
  }
  .menuItem {
    justify-content: flex-start;
  }
  .menuItem:hover {
    background-color: #2196f3;
  }
  .menuOpen {
    background-color: transparent !important;
    margin-top: 22px !important;
    background-color: #0b2447 !important;
    color: white !important;
    padding: 10px !important;
  }
  /* .menuOpen:hover {
    background-color: #2196f3;
  }
  .menuOpen:active {
    background-color: #2196f3;
  } */
  /* .menuItem:hover {
    background-color: #2196f3 !important;
  } */
  .menuItem:hover::before {
    background-color: #2196f3;
  }
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #0b2447;
    backdrop-filter: blur(20px);
    align-items: center;
    height: 70px;
  }
  .arrow {
    width: 50px;
    height: 50px;
  }
  .seeMore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 120px;
    align-items: center;
  }
  .ourPartnersTextDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
    background-color: #0b2447;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  .ourPartnersText {
    font-size: large;
    font-weight: bold;
    color: #7caaf4;
  }
  .ourPartnersCardDiv {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
    background-color: #0b2447;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    padding: 10px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .ourPartnersCardDiv::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .ourPartnersCard {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    height: 350px;
    background-color: #cbcbd4;
    border-radius: 20px;
    margin-right: 10px;
    transition: 300ms;
  }
  .ourPartnersCard:hover {
    margin-top: -20px;
  }

  .ourPartnersCardImgDiv {
    display: flex;
    flex-direction: row;
    width: 300px;
    justify-content: center;
    align-items: center;
    height: 250px;
  }
  .ourPartnersCardImg {
    width: 250px;
  }
  .ourServicesTextDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    background-color: #cbcbd4;
    justify-content: center;
    align-items: center;
  }
  .ourServicesText {
    color: #0b2447;
    font-size: larger;
    font-weight: bolder;
  }
  .ourServicesCardDiv {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    background-color: #cbcbd4;
    justify-content: space-evenly;
    align-items: center;
    overflow-x: scroll;
  }
  .ourServicesCard {
    width: 45%;
    height: 150px;
    display: flex;
    flex-direction: column;
    background-color: #0b2447;
    border-radius: 20px;
    align-items: center;
    transition: 300ms;
  }
  .ourServicesCardImg {
    width: 80px;
  }
  .ourServicesCardText {
    font-size: large;
    font-weight: bold;
    color: #2196f3;
  }
  .ourServicesCard:hover {
    margin-top: -20px;
  }
  .clientsSayTextDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #0b2447;
    width: 100%;
    height: 100px;
  }
  .clientsSayText {
    font-size: large;
    font-weight: bold;
    color: #7caaf4;
  }
  .ourClientsSayCardDiv {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
    background-color: #0b2447;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    padding: 10px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .ourClientsSayCardDiv::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .ourClientsSayCard {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 300px;
    height: 350px;
    background-color: #cbcbd4;
    border-radius: 20px;
    margin-right: 10px;
    overflow-y: scroll;
  }
  .ourClientsSayCardImgDiv {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 15px;
    align-items: center;
  }
  .ourClientsSayCardImg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  .ourClientsSayCardTextDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    align-items: center;
  }
  .top {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  .topImg {
    width: 60px;
  }
  .footerMob {
    display: flex;
    width: 100%;
    height: 200px;
    background-color: #0b2447;
    flex-direction: row;
    justify-content: center;
  }
  .innerFooterMob {
    width: 80%;
    height: 200px;
    border-top: 1px solid #2196f3;
    color: white;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .footerMobLinks {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
    flex-direction: row;
  }
  .footerMobLinksLeft {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 150px;
    color: white;
    justify-content: space-evenly;
    font-size: 12px;
    padding-top: 20px;
  }
  .footerMobLinksRight {
    width: 70%;
    height: 150px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .footerMobLinksRightAnimation {
    width: 100px;
  }
  .slider {
    width: 100%;
    height: 100svh;
    position: absolute;
  }
  .rightArrow {
    height: 400px;
  }
}
@media only screen and (max-width: 375px) {
  .mobText {
    font-size: 30px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .preventLandScape {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #0b2447;
    z-index: 20000;
    justify-content: center;
    align-items: center;
  }
  .rotate {
    width: 300px;
  }
  .main {
    display: none;
  }
  .mainMob {
    display: none;
  }
}
