.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: #0b2447;
  }
  .preventLandScape{
    display: none;
  }
  .construction {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 50px;
    text-align: center;
  }
  .left {
    width: 35%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .innerLeft {
    width: 90%;
    height: 90vh;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .imgSliderDiv {
    width: 100%;
    height: 100%;
    display: flex;
    background-size: cover;
    align-items: center;
    justify-content: center;
  }
  .imgSlider {
    width: 100%;
    height: 100%;
  }
  .slider {
    width: 100%;
    height: 100%;
    background-color: #0b2447;
    outline: none !important;
  }
  .img {
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 80vh;
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  .right {
    width: 65%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .innerRight {
    height: 90%;
    width: 90%;
    background-color: white;
    display: flex;
    border-radius: 20px;
    padding: 20px;
  }
  .aboutUsText{
    display: none;
  }
  .backToHomeDiv{
    display: flex;
    position: fixed;
    top: 10px;
    left: 10px;
  }
  .backToHome{
    width: 40px;
  }
  @media only screen and (max-width: 600px) {
    .container {
      width: 100%;
      height: 100svh;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
    }
    .left {
      display: none;
    }
    .right {
      display: none;
    }
    .top {
      display: flex;
      width: 90%;
      background-color: white;
      height: 65svh;
      border-radius: 20px;
      
      border: 1px solid #0b2447;
      flex-direction: column;
    }
    .bottom {
      display: flex;
      width: 90%;
      height: 30vh;
      background-color: white;
      border-radius: 20px;
      margin-top: 10px;
  
    }
  
    .top::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    .aboutUsTextDiv {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 7svh;
      background-color: #cbcbd4;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      justify-content: center;
      align-items: center;
    }
    .aboutUsText {
      width: 100%;
      height: 55svh;
      padding: 10px;
      overflow-x: scroll;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
    }
    .backToHomeImg{
      width: 40px;
      cursor: pointer;
    }
    .backToHome{
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 30px;
      right: 30px;
    }
    .preventLandScape{
      display: none;
    }
    .construction {
      font-size: 20px;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
    .preventLandScape {
      display: flex;
      width: 100vw;
      height: 100vh;
      background-color: #0b2447;
      z-index: 20000;
      justify-content: center;
      align-items: center;
    }
    .rotate {
      width: 300px;
    }
    .main {
      display: none;
    }
    .mainMob {
      display: none;
    }
    .content{
      display: none;
    }
    .left{
      display: none;
    }
    .right{
      display: none;
    }
    .backToHomeDiv{
      display: none;
    }
    .top{
      display: none;
    }
    .bottom{
      display: none;
    }
    .construction{
      display: none;
    }
  }
  
  