.container{
    width: 100%;
    height: 100vh;
    background-color: #0b2447;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.main{
    width: 80%;
    height: 80vh;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    
}
.lottie{
    width: 600px;
    z-index: 90;
}
.btn{
    height: 50px;
    width: 300px;
    border-radius: 8px;
    outline: none;
    border: none;
    background-color: #277ffb;
    
    font-weight: bolder;
    font-size: 13px;
    color: white;
    margin-top: -100px;
    z-index: 100;
    cursor: pointer;
}
@media only screen and (max-width: 600px) {
    .container{
        width: 100svw;
        height: 100svh;
        background-color: #0b2447;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .lottie{
        width: 400px;
        z-index: 90;
        margin-bottom: 90px;
    }
    .main{
        width: 80%;
        height: 60svh;
        background-color: white;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        position: relative;
        
    }
    .btn{
        position: absolute;
        bottom: 70px;
        left: 75px;
        width: 200px;
        
    }
}