body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @media screen and (min-width: 320px) and (max-width: 900) and (orientation: landscape) {
  body {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

@import "./fonts/font-face.css";
* {
  font-family: Poppins !important;
}
input {
  font-family: Poppins !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  box-sizing: border-box;
}
.slick-dots {
  bottom: 40px !important;
}
@media only screen and (max-width: 600px) {
  .slick-dots {
    bottom: 40px !important;
  }
}
.szh-menu__item--hover {
  background-color: #277ffb !important;
}