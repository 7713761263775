.container {
  background-color: #0b2447;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.left {
  width: 55%;
  height: 100vh;
  padding: 15vh;
  justify-content: space-between;
}
.right {
  width: 45%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-around;
}
.contact {
  width: 70%;
  height: auto;
  background-color: white;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
}
.from {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.input {
  height: 50px;
  width: 300px;
  outline: none;
  border: none;
  background: no-repeat scroll 7px 7px;
}
.btn {
  height: 50px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #277ffb;
  margin-top: 24px;
  font-weight: bolder;
  font-size: 13px;
  color: white;
}
.inputMessage {
  height: 100px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  justify-content: left;
  border: 1px solid gray;
  margin-bottom: 15px;
  background: no-repeat scroll 7px 7px;

  padding: 16px;
}
.inputComponent {
  border: 1px solid gray;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  width: 300px;
  align-items: center;
}
.icons {
  width: 30px;
  aspect-ratio: 1/1;

  margin-left: 10px;
}
.title {
  padding-top: none;
  color: white;
}
.leftText {
  color: white;
  font-size: larger;
}
.info {
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;
}
.call {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 350px;
  padding: 5px;
  margin-bottom: 10px;
  color: white;
}
.call::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 60px;
  border: 4px solid #277ffb;
  transform: scaleX(0);
  transform-origin: left;
  transition: 300ms ease;
}
.call:hover::before {
  transform: scaleX(1);
}
.address{
  position: relative;
  display: flex;
  flex-direction: row;
  width: 550px;
  padding: 5px;
  margin-bottom: 10px;
  color: white;
}
.address::before{
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 60px;
  border: 4px solid #277ffb;
  transform: scaleX(0);
  transform-origin: left;
  transition: 300ms ease;
}
.address:hover::before {
  transform: scaleX(1);
}
.callIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
}

.navlink {
  position: relative;
}
.navlink::before {
  content: "";
  border-top: 4px solid #0b2447;
  position: absolute;
  width: 100%;
  top: -22px;
  left: 0;
  transform: scaleX(0);
  transition: 300ms;
  /* transition-timing-function: linear; */
}
.navlink:hover::before {
  transform: scaleX(1);
}

.logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.logosImg {
  display: flex;
  flex-direction: column;
  width: 70px;
  aspect-ratio: 1/1;
  margin-right: 40px;
  align-items: center;
  justify-content: center;
  transition: 400ms;
  border-radius: 50%;
}
.logosImg:hover {
  background-color: #277ffb;
  border-radius: 50%;
}
.iconItself {
  width: 30px;
}
.formMob {
  display: none;
}
.quoteSent {
  display: none;
}
.backToHome {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 20px;
  right: 20px;
}
.backToHomeImg {
  width: 40px;
  cursor: pointer;
}
.preventLandScape {
  display: none;
}
@media only screen and (max-width: 600px) {
  .container {
    height: auto;
    display: flex;
    background-color: #0b2447;

    padding: 0;
  }
  .title {
    margin-left: 20px;
  }
  .leftText {
    margin-left: 20px;
    width: 90%;
  }
  .preventLandScape {
    display: none;
  }
  .right {
    display: none;
  }
  .left {
    width: 100%;
    padding: 0px;
    justify-content: space-evenly;
    background-color: #0b2447;
    height: auto;
  }

  .formMob {
    display: flex;
    flex-direction: column;
    width: 90%;
    background-color: white;
    border-radius: 20px;
    padding-bottom: 30px;
    align-items: center;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .lottieMob {
    width: 150px;
    margin-top: -25px;
  }

  .call {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 250px;
    padding: 5px;
    margin-bottom: 10px;
    color: white;

    transition: 100ms;
  }
  .call:hover {
    border-radius: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 350px;
    margin-bottom: 10px;
    color: white;
  }
  .address{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 350px;
    padding: 5px;
    margin-bottom: 10px;
    color: white;
    font-size: 13px;
    transition: 100ms;
  }
  .address:hover{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 350px;
    padding: 5px;
    margin-bottom: 10px;
    color: white;
    
    transition: 100ms;
  }
  .info {
    display: flex;
    flex-direction: column;
    height: 30vh;
    justify-content: center;
    background-color: #0b2447;
    padding-left: 20px;
    margin-top: 20px;
  }
  .logos {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #0b2447;
    height: 90px;
    border: 1px solid #0b2447;
    align-items: center;
  }
  .logosImg {
    display: flex;
    flex-direction: row;
    width: 70px;
    transition: 400ms;
    border-radius: 50%;
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .logosImg:hover {
    background-color: #277ffb;
    border-radius: 50%;
  }
  .quoteSent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .preventLandScape {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #0b2447;
    z-index: 20000;
    justify-content: center;
    align-items: center;
  }
  .rotate {
    width: 300px;
  }
  .main {
    display: none;
  }
  .mainMob {
    display: none;
  }
  .content {
    display: none;
  }
  .left {
    display: none;
  }
  .right {
    display: none;
  }
}
