.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-color: #0b2447;
  align-items: center;
  justify-content: center;
}
.loading {
  color: white;
  font-size: 100px;
}
.whiteTruck {
  width: 300px;
}
.content {
  display: flex;
  flex-direction: row;
  width: 90%;
}
.left {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}
.innerLeft {
  background-color: white;
  width: 100%;

  height: 85vh;
  border-radius: 30px 0px 0px 30px;
  background-color: #2196f3;
}
.right {
  background-color: #0b2447;
  width: 80%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.innerRight {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 85vh;
  border-radius: 0px 30px 30px 0px;
}
.innerRightDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 85vh;
}
.userName {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 26vh;
  align-items: center;
  border-bottom: 2px solid #2196f3;
  margin-bottom: 10px;
}
.userNameText {
  color: white;
  font-size: large;
  font-weight: bold;
  margin-bottom: 10px;
}
.profileLogout {
  width: 30px;
  cursor: pointer;
}
.profileLogoutDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
  padding-right: 10px;
}
.profile {
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;

  transition: 300ms;
}
.profile::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #0b2447;
  transform: scaleX(0);
  transition: 300ms;
}
.profile:hover::after {
  transform: scaleX(1);
}
.profileText {
  color: white;
  font-size: large;
  font-weight: bold;
  z-index: 2;
}
.profilePicDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 15vh;
  width: 100%;
  justify-content: center;
}
.profilePic {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.input {
  height: 50px;
  width: 220px;
  outline: none;
  border: none;
  background: no-repeat scroll 7px 7px;
}
.inputComponent {
  border: 1px solid gray;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  width: 300px;
  align-items: center;
}
.icons {
  width: 30px;
  aspect-ratio: 1/1;

  margin-left: 10px;
}
.innerRightPic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 20px;
  background-size: contain;
  object-fit: cover;
}
.btn {
  height: 50px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #277ffb;
  font-weight: bolder;
  font-size: 13px;
  color: white;
}
.btn:disabled {
  background-color: gray;
  cursor: auto;
}
.commentCard {
  display: flex;
  flex-direction: column;
  width: 330px;
  height: 40vh;
  border: 1px solid gray;
  border-radius: 20px;
  margin: 30px;
  box-shadow: 0px 1px 5px;
  align-items: center;
  padding: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.commentDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
}
.userComment {
  text-align: justify;
  text-justify: inter-word;
}
.orderDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.orderCard {
  position: relative;
  width: 80%;
  min-height: 55vh;
  border: 1px solid black;
  border-radius: 20px;
  box-shadow: 0px 1px 5px;
  margin-bottom: 20px;
  flex-direction: column;
  background-color: #0b2447;
  display: flex;
  justify-content: center;
  
}
.orderStatus {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 50px;
  
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgba(88, 112, 248, 0.3);
  backdrop-filter: blur(6px);
  display: flex;
  z-index: 1900;
  padding-left: 10px;
  justify-content: center;
  align-items: center;
}
.orderStatusText {
  font-size: medium;
  font-weight: bold;
  color: #0b2447;
}
.commentLight {
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
}
.orderDetail {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20%;
  background-color: #0b2447;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  justify-content: space-between;
}
.orderSingleDetail {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  height: 100%;
  padding: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  align-items: center;
}
.orderIcon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.orderDetailName {
  color: #2196f3;
  margin-top: 5px;
  font-weight: bolder;
}
.loginMode {
  width: 30%;
  height: 45vh;
  display: flex;
  background-color: white;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
}
.lockedAnimation {
  width: 200px;
}
.homeBtn {
  height: 50px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #277ffb;
  font-weight: bolder;
  font-size: 13px;
  color: white;
  margin-right: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.commentImg {
  width: 100px;
  border-radius: 50%;
}
.orderIcontext {
  display: flex;
  flex-direction: row;
}
.orderData {
  color: #2196f3;
  padding: 0;
  margin: 0;
  font-size: large;
  font-weight: bold;
}

.iconsMob {
  display: none;
}
.preventLandScape {
  display: none;
}

@media only screen and (max-width: 600px) {
  .orderStatus{
    width: 70%;
  }
  .container {
    min-width: 100%;
    height: 100svh;
    background-color: #0b2447;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .preventLandScape {
    display: none;
  }
  .input {
    width: 150px;
  }
  .loginMode {
    width: 90%;
    height: auto;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .lockedAnimation {
    width: 200px;
  }

  .homeBtn {
    height: 50px;
    width: 200px;
    border-radius: 8px;
    outline: none;
    border: none;
    background-color: #277ffb;
    font-weight: bolder;
    font-size: 13px;
    color: white;
    margin-right: 10px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .profile {
    display: none;
  }
  .innerRightPic {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .inputComponent {
    width: 200px;
  }
  .profilePicDiv {
    display: none;
  }
  .left {
    width: 15%;
    height: 100svh;
  }
  .right {
    width: 85%;
    height: 100svh;
  }
  .btn {
    width: 200px;
  }
  .userName {
    display: none;
  }
  .profileMob {
    width: 40px;
    margin-bottom: 10px;
    height: 40px;
  }
  .innerLeft {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95svh;
  }
  .innerRight {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 95svh;
  }
  .iconsMob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .content {
    width: 95%;
  }
  .orderCard {
    width: 95%;
    min-height: 55vh;
    border: 1px solid black;
    border-radius: 20px;
    box-shadow: 0px 1px 5px;
    margin-top: 10px;
    flex-direction: column;
    background-color: #0b2447;
  }
  .orderDiv {
    padding: 0px;
  }
  .orderIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .orderDetailName {
    font-size: small;
  }
  .orderData {
    font-size: small;
  }
  .commentDiv {
    padding: 5px;
  }
  .commentCard {
    width: 100%;
  }
  .profileLogoutDiv {
    justify-content: center;
    padding: 0px;
    width: 40px;
  }
  .loading {
    font-size: 50px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .preventLandScape {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #0b2447;
    z-index: 20000;
    justify-content: center;
    align-items: center;
  }
  .rotate {
    width: 300px;
  }
  .main {
    display: none;
  }
  .mainMob {
    display: none;
  }
  .content {
    display: none;
  }
}
