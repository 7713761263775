.container {
  width: 100%;
  height: 100vh;
  background-color: #0b2447;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading {
  color: white;
  font-size: 100px;
}
.whiteTruck {
  width: 300px;
}
.main {
  width: 80%;
  height: 90vh;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.preventLandScape {
  display: none;
}
.bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 13vh;
  align-items: center;
  justify-content: center;
}
.part1 {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border: 2px solid gray;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 300ms;
}
.part1.active {
  background-color: #fcae1e;
}
.line {
  width: 120px;
  border-top: 2px solid gray;
}
.done {
  width: 20px;
}
.num {
  font-size: large;
  font-weight: bold;
  color: gray;
}
.bottom {
  height: 77vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.pt1btn {
  height: 50px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #277ffb;
  font-weight: bolder;
  font-size: 13px;
  color: white;
  margin-right: 10px;
}
.pt1btnLast {
  height: 50px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #277ffb;
  font-weight: bolder;
  font-size: 13px;
  color: white;
  margin-right: 10px;
}
.pt1btnBack {
  height: 50px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #277ffb;
  font-weight: bolder;
  font-size: 13px;
  color: white;
  margin-right: 10px;
}
.pt1Bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 15vh;
}
.leveltext {
  font-size: large;
  font-weight: bold;
}
.leveltext3 {
  font-size: large;
  font-weight: bold;
}
.form {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.carCard {
  width: 200px;
  height: 100px;
  border: 3px solid black;
  margin-right: 20px;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 10px;
}
.carCardSelected {
  width: 200px;
  height: 100px;
  border: 3px solid #277ffb;
  margin-right: 20px;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 10px;
}
.cardSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.cardImg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: -50px;
}
.input {
  height: 50px;
  width: 300px;
  outline: none;
  border: none;
  background: no-repeat scroll 7px 7px;
}
.inputComponent {
  border: 1px solid gray;
  border-radius: 20px;
  display: flex;
  flex-direction: row;

  width: 300px;
  align-items: center;
  margin-top: 20px;
}
.icons {
  width: 30px;
  aspect-ratio: 1/1;

  margin-left: 10px;
}
.topCardImg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5px;
}
.orderSuccess {
  width: 400px;
  height: 77vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.orderSuccessText {
  font-size: large;
  font-weight: bold;
}
.datePicker {
  height: 50px;
  width: 300px;
  outline: none;
  border: none;
  background: no-repeat scroll 7px 7px;
}
.dateComponent {
  border: 1px solid gray;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  width: 300px;
  align-items: center;
  margin-top: 20px;
}
.loginMode {
  width: 30%;
  height: auto;
  display: flex;
  background-color: white;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
}
.lockedAnimation {
  width: 200px;
}
.homeBtn {
  height: 50px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #277ffb;
  font-weight: bolder;
  font-size: 13px;
  color: white;
  margin-right: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.map {
  width: 100%;
  height: 60vh;
}
.formContainer {
  height: 77vh;
  display: flex;
  padding-top: 270px;
  flex-direction: column;
  overflow-y: scroll;
}
.addresses {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
@media only screen and (max-width: 600px) {
  .addresses{
    padding-bottom: 160px;
  }
  .container {
    width: 100%;
    max-height: 100svh;
    background-color: #0b2447;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .loading {
    font-size: 50px;
  }
  .map {
    height: 100%;
  }
  .formContainer {
    margin-top: 10px;
    overflow-y: scroll !important;
    height: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }
  .loginMode {
    width: 90%;
    height: auto;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
  }
  .lockedAnimation {
    width: 200px;
  }
  .homeBtn {
    height: 50px;
    width: 200px;
    border-radius: 8px;
    outline: none;
    border: none;
    background-color: #277ffb;
    font-weight: bolder;
    font-size: 13px;
    color: white;
    margin-right: 10px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .main {
    width: 100%;
    height: 100svh;
    border-radius: 0px;
  }
  .bar {
    position: fixed;
    z-index: 100;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(6px);
    height: 16vh;
  }

  .bottom {
    z-index: 0;
    height: 100%;
  }
  .leveltextDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    z-index: 100;
    top: 10vh;
  }
  .pt1Bottom {
    position: fixed;
    top: 85vh;
  }
  .pt1btn {
    width: 100px;
    margin-right: 5px;
  }
  .pt1btnLast {
    width: 200px;
    margin-right: 5px;
    position: fixed;
    bottom: 30px;
    z-index: 100;
  }
  .pt1btnBack {
    width: 100px;
    margin-left: 5px;
  }

  .btnDiv {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    bottom: 40px;
    z-index: 1000;
  }
  .form {
    width: 100%;
    left: 0;
    margin-top: 300px;
    height: 100%;
    padding-bottom: 100px;
  }
  .carCard {
    width: 150px;
    margin-right: 5px;
  }
  .carCardSelected {
    width: 150px;
    margin-right: 5px;
  }
  .leveltext3 {
    font-size: small;
  }
  .cardSection {
    justify-content: space-around;
  }
  .orderSuccessText {
    position: fixed;
    top: 10vh;
    z-index: 100;
  }
  .orderSuccessText2 {
    position: fixed;
    top: 70vh;
    z-index: 100;
  }
  .orderSuccess {
    margin-top: 70px;
  }
  .preventLandScape {
    display: none;
  }
}
@media only screen and (max-width: 375px) {
  .orderSuccessText2 {
    position: fixed;
    top: 15vh;
    z-index: 100;
    font-size: 15px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .preventLandScape {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #0b2447;
    z-index: 20000;
    justify-content: center;
    align-items: center;
  }
  .rotate {
    width: 300px;
  }
  .main {
    display: none;
  }
  .mainMob {
    display: none;
  }
  .content {
    display: none;
  }
  .left {
    display: none;
  }
  .right {
    display: none;
  }
}
