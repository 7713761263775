.content {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  border: none;
  outline: none !important;
}
.container {
  display: flex;
  flex-direction: column;
}
.top {
  width: 350px;
  height: 10vh;
  background-color: #0b2447;
  display: flex;
  border-radius: 20px;
  z-index: 100;
  box-shadow: 0px 1px 5px;
  padding: 10px;
  flex-direction: row;
  justify-content: center;
}
.bottom {
  width: 350px;
  height: 70vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: -35px;
  border-radius: 20px;
  padding-top: 40px;
  align-items: center;
  justify-content: center;
}
.topText {
  font-size: larger;
  font-weight: bolder;
  color: #2196f3;
}
.btn {
  height: 50px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #277ffb;
  margin-top: 24px;
  font-weight: bolder;
  font-size: 13px;
  color: white;
}
.btn:disabled {
  background-color: gray;
}
.inputComponent {
  border: 1px solid gray;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 300px;
  align-items: center;
}
.inputRateComponent {
  border: 1px solid gray;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 60px;
  align-items: center;
}
.Rateinput {
  height: 50px;
  width: 50px;
  outline: none;
  border: none;
  background: no-repeat scroll 7px 7px;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
}
.icons {
  width: 30px;
  aspect-ratio: 1/1;
  margin-top: -5px;
  margin-left: 10px;
}
.input {
  height: 50px;
  width: 300px;
  outline: none;
  border: none;
  background: no-repeat scroll 7px 7px;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
}
.Commentinput {
  height: 300px;
  width: 300px;
  outline: none;
  border: none;
  background: no-repeat scroll 7px 7px;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
}
.from {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

