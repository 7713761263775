.main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.main * {
  margin-right: 12px;
  margin-left: 12px;
  text-decoration: none;
  font-size: 20px;
  color: white;
}

.nav {
  margin: 50px;
  border-top: 1px solid white;

  padding-top: 20px;
}
.navlink {
  position: relative;
}
.navlinkLogo {
  position: relative;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgba(255, 255, 255);
  /* backdrop-filter: blur(6px); */
  margin-top: -10px;
  height: 50px;
}
.navlink::before {
  content: "";
  border-top: 4px solid #0b2447;
  position: absolute;
  width: 100%;
  top: -22px;
  left: 0;
  transform: scaleX(0);
  transition: 300ms;
  /* transition-timing-function: linear; */
}
.navlink:hover::before {
  transform: scaleX(1);
}
.navlinkLogo::before {
  content: "";
  border-top: 4px solid #0b2447;
  position: absolute;
  width: 100%;
  top: -12px;
  left: 0;
  transform: scaleX(0);
  transition: 300ms;
  /* transition-timing-function: linear; */
}
.navlinkLogo:hover::before {
  transform: scaleX(1);
}
.navlinkText{
  font-weight: bold;
  font-size: large;
}
.universeLogo{
  width: 200px;
  margin-top: 5px;
}
.navlinkLogin {
  position: relative;
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.navlinkLogin::before {
  content: "";
  border-top: 4px solid #0b2447;
  position: absolute;
  width: 100%;
  top: -22px;
  left: 0;
  transform: scaleX(0);
  transition: 300ms;
  /* transition-timing-function: linear; */
}
.navlinkLogin:hover::before {
  transform: scaleX(1);
}

.dropbtn {
  font-size: largee;
  border: none;
  background-color: transparent;
  margin-top: -5px;
}
.dropdownContent {
  display: none;
  position: absolute;
  background-color: transparent;
  min-width: 160px;
  transition: 300ms;
  z-index: 1;
}
.dropdownContent a {
  color: white;
  padding-top: 7px;
  padding-bottom: 7px;
  text-decoration: none;
  display: block;
  margin-left: 5px;
}
.dropdownContent a:hover {
  background-color: #0b2447;
}
.navlinkLogin:hover .dropdownContent {
  display: block;
}

.profile {
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  transition: 300ms;
}
.profile::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #0b2447;
  transform: scaleX(0);
  transition: 300ms;
}
.profile:hover::after {
  transform: scaleX(1);
}
.menuItem {
  justify-content: center;
}
.menuOpen {
  background-color: transparent !important;
  margin-top: 10px !important;
  background-color: #0b2447 !important;
  color: white !important;
  padding: 10px !important;
  justify-content: center !important;
}
.menuItem:hover {
  background-color: #2196f3;
}
.menuItem:active {
  background-color: #2196f3;
}
